(function() {
  angular.module('app.smartlaw.base')
    .factory('Account', ['$http', '$q', 'slConstant', function($http, $q, slConstant) {
      var smartlawId;
      var account = {};
      var isBusiness = false;
      var orderSubmitted = false;

      var setCountry = function() {
        account.country = "Germany";
        account.countryTranslation = "Deutschland";
        account.countryIso = "de";
      };

      var initAccount = function(sid) {
        if (sid === "undefined" || sid === undefined) {
          setCountry();
          return $q.when();
        } else {
          return $http.get(slConstant.host + '/accounts/' + sid).then(function(result) {
            smartlawId = sid;
            account = result.data;


            setCountry();
            return account;
          });
        }
      };

      var updateAccount = function(sid) {
        return $http.get(slConstant.host + '/accounts/' + sid).then(function(result) {
          smartlawId = sid;
          account.Id = result.data.Id;
          account.contactId = result.data.contactId;
          setCountry();
          return account;
        });
      };

      var hasAgreed = function() {
        return !!getAccount().cdpConsent;
      };

      var setBusiness = function() {
        isBusiness = true;
      };

      var getBusiness = function() {
        return isBusiness;
      };

      var getAccount = function() {
        return account;
      };

      var saveAccount = function() {
        return $http.put(slConstant.host + '/accounts/' + smartlawId, {account: account});
      };

      var getSmartlawId = function() {
        return smartlawId;
      };

      var isDefined = function() {
        return account.Id !== undefined;
      };

      var setOrderSubmittedState = function(state) {
        orderSubmitted = state;
      }

      var getOrderSubmittedState = function() {
        return !!orderSubmitted;
      }

      return {
        getSmartlawId: getSmartlawId,
        initAccount: initAccount,
        updateAccount: updateAccount,
        getAccount: getAccount,
        saveAccount: saveAccount,
        isDefined: isDefined,
        setBusiness: setBusiness,
        getBusiness: getBusiness,
        hasAgreed: hasAgreed,
        setOrderSubmittedState: setOrderSubmittedState,
        getOrderSubmittedState: getOrderSubmittedState
      };
    }])

    .factory('PaymentMethod', ['$http', '$q', 'Account', 'slConstant', function($http, $q, Account, slConstant) {
      var paymentPageParams;
      var paymentDeferred;
      var method;

      var initPaymentMethod = function(paymentMethod) {
        method = paymentMethod;

        if (method === 'paypal') {
          return $q.when();
        }

        return $q(function(resolveInit, rejectInit) {
          $http.get(slConstant.host +'/payments/' + paymentMethod + '/signature').then(function(result) {
            var params = result.data;
            paymentPageParams = result.data;

            Z.runAfterRender(function() {
              resolveInit();
            });

            var prepopulate = {};
            if (paymentMethod === 'sepa') {
              if (Account.getAccount().email) prepopulate.email = Account.getAccount().email ;
              if (Account.getAccount().firstName) prepopulate.firstName = Account.getAccount().firstName;
              if (Account.getAccount().lastName) prepopulate.lastName = Account.getAccount().lastName;
              prepopulate.country = 'DEU';
              //prepopulate.bankAccountName = Account.getAccount().firstName + " " + Account.getAccount().lastName;
            }
            if (paymentMethod === 'creditcard') {
              if (Account.getAccount().email) prepopulate.email = Account.getAccount().email ;
              if (Account.getAccount().firstName && Account.getAccount().lastName) prepopulate.creditCardHolderName = Account.getAccount().firstName + " " + Account.getAccount().lastName;

            }
            Z.renderWithErrorHandler(params, prepopulate,  function(response) {
              if (response.success) {
                if (paymentDeferred !== undefined) {
                  paymentDeferred.resolve(response.refId);
                }
              } else {
                if (paymentDeferred !== undefined) {
                  var errorMessage = "Bitte überprüfen Sie die Angaben zu Ihrer Zahlungsart.";
                  paymentDeferred.reject(errorMessage);
                }
              }
            }, function(key, code, message){
              var result = {
                key: key,
                code: code,
                message: message
              };
              var errorMessage = "Die Angaben zu Ihrer Zahlungsart sind fehlerhaft.";
              if (result.key !== undefined && result.message !== undefined) {
                if (result.key !== 'error') {
                  Z.sendErrorMessageToHpm(result.key, result.message);
                }
              }
              if (paymentDeferred !== undefined) {
                paymentDeferred.reject(errorMessage);
              }
            });
          }).catch(function() {
            rejectInit("Die Zahlungsmethode konnte nicht geladen werden. Bitte versuchen Sie es erneut oder kontaktieren Sie unseren Kundenservice. Sie erreichen uns Montag bis Freitag jeweils von 8 - 18 Uhr unter 0800 2684268 oder service@smartlaw.de. Vielen Dank für Ihr Verständnis.");
          });
        });
      };

      var submitPaymentMethod = function() {
        paymentDeferred = $q.defer();
        try {
          Z.submit();
        } catch(err) {
          paymentDeferred.reject("Bitte überprüfen Sie die Angaben zu Ihrer Zahlungsart.");
        }
        return paymentDeferred.promise;
      };

      var getPaymentPageParams = function() {
        return paymentPageParams;
      };

      var getMethod = function() {
        return method;
      };

      return {
        initPaymentMethod: initPaymentMethod,
        submitPaymentMethod: submitPaymentMethod,
        getPaymentPageParams: getPaymentPageParams,
        getMethod: getMethod
      };
    }])

    .factory('Validator', ['$q', 'Account', function($q, Account) {
      var validationErors = [];
      var newValidationErrors = [];

      var addError = function(field) {
        validationErors.push(field);
      };

      var hasError = function(field) {
        return validationErors.indexOf(field) > -1;
      };

      var validate = function() {
        return $q(function(resolve, reject) {
          validationErors = [];
          newValidationErrors = [];
          EMAIL_REGEX = /^(([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~])+)*)@((([a-z]|\d)(([a-z]|\d)|[-]([a-z]|\d)+)*)\.)+([a-z]{2,})$/i;

          if (Account.getAccount().firstName === undefined || Account.getAccount().firstName === '') addError('firstName');
          if (Account.getAccount().lastName === undefined || Account.getAccount().lastName === '') addError('lastName');
          if (Account.getAccount().country === undefined || Account.getAccount().country === '') addError('country');
          if (Account.getAccount().email === undefined || Account.getAccount().email === '') {
            addError('email');
            addError('email-missing');
          } else if (!EMAIL_REGEX.test(Account.getAccount().email)) {
            addError('email');
            addError('email-format')
          }

          if (Account.getBusiness()) {
            if (!Account.getAccount().city) addError('city');
            if (!Account.getAccount().zipCode) addError('zipCode');
            if (!Account.getAccount().street) addError('street');
            if (!Account.getAccount().company) addError('company');

            if (!Account.hasAgreed()) {
              if(!Account.getAccount().agreedToTerms) {
                addError('agreed');
              }
            }
          }

          if(Account.getOrderSubmittedState()) {
            if (!Account.getAccount().agreedToWiderrruf) {
              newValidationErrors.push('agreedWiderrruf');
            }
          }

          if (validationErors.length > 0) return reject('Bitte füllen Sie die erforderlichen Felder korrekt aus.');
          if (newValidationErrors.length > 0) return reject('Bitte stimmen Sie der Einwilligung zum Ausschluss des Widerrufsrechts zu.');
          resolve();
        });
      };

      var getResult = function() {
        return validationResult;
      };

      return {
        getResult: getResult,
        validate: validate,
        hasError: hasError
      };
    }])

    .factory('Order', ['$window', '$http', '$q', '$timeout', 'Account', 'PaymentMethod', 'Validator', 'slConstant', '$cookies', function($window, $http, $q, $timeout, Account, PaymentMethod, Validator, slConstant, $cookies) {
      var order = {
        rateplan: {},
        subscription: {}
      };
      var status;
      var error;
      var bNeedsSEPAConfirmation;
      var sepaConfirmationDeferred;
      var state;

      var initOrder = function(rateplanId, voucherCode) {
        /*if (Account.getAccount().Id === undefined) {
          return $q.reject("Die Buchung wird noch verarbeitet.");
        }*/
        var data = {rateplanId: rateplanId};
        if (Account.getAccount().Id !== undefined) data.accountId = Account.getAccount().Id;
        if (voucherCode !== undefined) data.voucherCode = voucherCode;

        return $http.post(slConstant.host + '/order/init', data).then(function(result) {
          order = result.data;
          var rateplan = result.data.rateplan;
          var split = rateplan.Name.indexOf(" ");
          if (order.rateplan.SubscriptionType__c === "Subscription") {
            order.rateplan.intro = rateplan.Name.substr(0, split);
            order.rateplan.basename = rateplan.Name.substr(split);
          } else {
            order.rateplan.intro = "Dokument";
            order.rateplan.basename = rateplan.Name;
          }
          return order;
        });
      };

      var removeVoucherCode = function() {
        delete order.voucher;
      };

      var createTimeout = function() {
        return $timeout(function() {
          return $q.reject("Die Bestellung konnte nicht abgeschlossen werden. Bitte versuchen Sie es zu einem späteren Zeitpunkt erneut");
        }, 15000);
      };

      var checkout = function(useExistingPaymentMethod) {
        error = null;
        status = null;

        var checkoutData = {
          rateplanId: order.rateplan.Id,
          account: Account.getAccount(),
          smartlawId: Account.getSmartlawId()
        };

        return $q(function(resolve, reject) {
          $q.when(function() {
            return true;
          }).then(function() {
            if (order.voucher !== undefined && !!order.voucher.voucherCode) {
              status = "Der Gutschein wird eingelöst...";
              return $http.post(slConstant.host +'/order/voucher-code/' + order.voucher.voucherCode + '/init', {accountId: Account.getAccount().Id, rateplanId: order.rateplan.Id })
            }
          }).then(function() {
            if (useExistingPaymentMethod) {
              if (Account.getAccount().paymentMethod !== undefined) {
                return;
              }
              return $q.reject('Keine Zahlungsmethode angegeben.');
            }
            status = "Die Zahlungsmethode wird überprüft...";
            if (PaymentMethod.getMethod() !== 'paypal') {
              return $q.race([PaymentMethod.submitPaymentMethod(), createTimeout()]);
            }
            return $q(function(resolvePaypal, rejectPaypal) {
              $http.post(slConstant.host +'/paypal/init/adaptive', {maxAmount: order.subscription.amount}).then(function(response) {
                checkoutData.preapprovalKey = response.data.preapprovalKey;
                checkoutData.order = order;
                if (order.voucher !== undefined) checkoutData.voucherCode = order.voucher.voucherCode;
                checkoutData.state = state;
                $cookies.putObject('checkoutData', checkoutData);
                status = "Sie werden zu Paypal weitergeleitet...";
                if (response.data.preapprovalKey === undefined) rejectPaypal('Paypal ist nicht erreichbar.');
                $window.location.href = slConstant.paypalHost + '/cgi-bin/webscr?cmd=_ap-preapproval&preapprovalkey=' + response.data.preapprovalKey;
              }).catch(function() {
                rejectPaypal('Paypal ist nicht erreichbar.');
              });
            });
          }).then(function(refId) {
            if (PaymentMethod.getMethod() !== 'sepa') {
              return refId;
            }
            sepaConfirmationDeferred = $q.defer();
            sepaConfirmationDeferred.refId = refId;
            bNeedsSEPAConfirmation = true;

            return sepaConfirmationDeferred.promise;
          }).then(function(refId) {
            if (refId !== undefined) checkoutData.paymentMethodId = refId;
            if (order.voucher !== undefined) checkoutData.voucherCode = order.voucher.voucherCode;
            status = "Die Bestellung wird verarbeitet...";

            return $http.post(slConstant.host +'/order/checkout', checkoutData);
          }).then(function(response) {
            status = null;
            var data = {
              order: order,
              subscription: response.data,
              account: Account.getAccount(),
              smartlawId: Account.getSmartlawId()
            };
            resolve(data);
          }).catch(function(response) {
            status = null;
            var errorMessage;
            if (response.data !== undefined || response.errorMessage !== undefined) {
              if (response.data.errorMessage !== undefined) {
                error = response.data.errorMessage;
              }
              if (response.errorMessage !== undefined) {
                error = response.errorMessage;
              }
              errorMessage = error
            } else {
              error = response;
            }
            reject(errorMessage);
          });
        });
      };

      var continueCheckout = function() {
        return $q(function(resolve, reject) {
          var checkoutData = $cookies.getObject('checkoutData');
          if (!checkoutData) return reject();
          state = checkoutData.state;
          resolve(checkoutData);
        }).then(function(checkoutData) {
          return $http.post(slConstant.host + '/paypal/confirm/adaptive', {'accountId': checkoutData.account.Id,'preapprovalKey': checkoutData.preapprovalKey}).then(function(response) {
            var refId = response.data;
            if (refId !== undefined) checkoutData.paymentMethodId = refId;
            status = "Die Bestellung wird verarbeitet...";
            return $http.post(slConstant.host +'/order/checkout', checkoutData).then(function(response) {
              $cookies.remove('checkoutData');
              status = null;
              return {
                order: checkoutData.order,
                account: checkoutData.account,
                smartlawId: checkoutData.smartlawId,
                state: checkoutData.state,
                subscription: response.data
              };
            });
          });
        });
      };

      var getSavedState = function() {
        var checkoutData = $cookies.getObject('checkoutData');
        if (!checkoutData) return null;
        return checkoutData.state;
      };

      var confirmMandate = function() {
        if (sepaConfirmationDeferred !== undefined) {
          sepaConfirmationDeferred.resolve(sepaConfirmationDeferred.refId);
        }
        bNeedsSEPAConfirmation = false;
      };

      var declineMandate = function() {
        if (sepaConfirmationDeferred !== undefined) {
          sepaConfirmationDeferred.reject("Sie haben das SEPA-Lastschriftmandat nicht angenommen.");
        }
        bNeedsSEPAConfirmation = false;
      };

      var getOrder = function() {
        return order;
      };

      var getStatus = function() {
        return status;
      };

      var getError = function() {
        return error;
      };

      var setStatus = function(value) {
        return status = value;
      };

      var setError = function(value) {
        return error = value;
      };

      var clearError = function() {
        error = null;
      };

      var needsSEPAConfirmation = function() {
        return bNeedsSEPAConfirmation;
      };

      var setState = function(data) {
        state = data;
      };

      var getState = function() {
        return state;
      };


      var hasRateplan = function() {
        return order.rateplan && order.rateplan.Id;
      };

      return {
        checkout: checkout,
        hasRateplan: hasRateplan,
        getOrder: getOrder,
        initOrder: initOrder,
        removeVoucherCode: removeVoucherCode,
        getStatus : getStatus,
        setStatus: setStatus,
        getError: getError,
        setError: setError,
        needsSEPAConfirmation: needsSEPAConfirmation,
        confirmMandate: confirmMandate,
        declineMandate: declineMandate,
        clearError: clearError,
        setState: setState,
        getState: getState,
        continueCheckout: continueCheckout,
        getSavedState: getSavedState
      };
    }]);
})();
