(function() {
  'use strict';

  angular
    .module('app.customer')
    .directive('slCustomerCenter', ['slConstant', function(slConstant) {
      return {
        restrict: 'E',
        scope: {
          rpid: '=',
          aid: '='
        },
        replace: true,
        templateUrl: slConstant.host + '/customer',
        controllerAs: 'customerCtrl',
        controller: 'CustomerCenterCtrl'
      }
    }]);
})();
