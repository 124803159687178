(function() {
  'use strict';

  angular.module('app.smartlaw.base', ['ngCookies'])
    .config(['$sceDelegateProvider', function($sceDelegateProvider) {
      $sceDelegateProvider.resourceUrlWhitelist([
        'self',
        'https://sa-billing.smartlaw.de/**'
      ]);
    }]);
})();
