(function() {
  'use strict';

  angular
    .module('app.checkout')
    .directive('slCheckout', ['slConstant', '$location', function(slConstant, $location) {
      return {
        restrict: 'E',
        scope: {
          rpid: '=',
          aid: '=',
          state: '='
        },
        replace: true,
        templateUrl: slConstant.host + '/checkout' + ($location.search().paypal == 'show' ? '?paypal=show' : ''),
        controllerAs: 'checkoutCtrl',
        controller: 'CheckoutCtrl'
      }
    }]);
})();
