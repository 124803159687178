(function() {
  'use strict';

  angular
    .module('app.customer')
    .controller('CustomerCenterCtrl', ['Account', 'Invoice', 'Subscription', 'PaymentMethod', 'Validator', 'slConstant', '$scope', '$http', '$q', '$timeout', function(Account, Invoice, Subscription, PaymentMethod, Validator, slConstant, $scope, $http, $q, $timeout) {
      var vm = this;

      var aid = $scope.aid;

      vm.getAccount = Account.getAccount;
      vm.getInvoices = Invoice.getInvoices;
      vm.getSubscriptions = Subscription.getSubscriptions;
      vm.getPaymentPageParams = PaymentMethod.getPaymentPageParams;
      vm.paymentPageLoading = false;

      vm.imageCreditcardUrl = slConstant.host + "/images/logo_mastercard+visa.svg";
      vm.imageSepaUrl = slConstant.host + "/images/logo_lastschrift.svg";
      vm.imagePaypalUrl = slConstant.host + "/images/logo_paypal.svg";

      vm.showKundigungPopUp = false;
      vm.typeOfCancellation = "ordentlich";
      vm.isDisabled = false;

      var status = {};
      var error = {};
      var idle = {};

      var sepaConfirmationDeferred;
      var bNeedsSEPAConfirmation;

      if (aid !== undefined) {
        Account.initAccount(aid).then(function() {
          return Invoice.initInvoices();
        }).then(function() {
          if (Account.hasAgreed()) {
            vm.hasCDPAgreed = true;
          }
          return Subscription.initSubscriptions();
        }).then(function() {

          vm.isBusiness = Subscription.isBusiness();

          if (vm.isBusiness) {
            Account.setBusiness();
          }

          vm.isCustomerReady = true;
        }).catch(function(error) {
          vm.isCustomerReady = true;
          vm.errorMessage = error.data.errorMessage;
        });
      } else {
        vm.isCustomerReady = true;
        vm.errorMessage = 'Account konnten nicht gefunden werden';
      }

      var isIdle = function(section) {
        return idle[section];
      };

      vm.isIdle = isIdle;

      vm.getPaymentMethodText = function() {
        if (Account.getAccount().paymentMethod !== undefined) {
          var paymentMethod = Account.getAccount().paymentMethod;
          if (paymentMethod.Type ==='BankTransfer') {
            return 'Sie zahlen derzeit mit einer SEPA Lastschrift über das Konto ' + paymentMethod.BankTransferAccountNumberMask + '.';
          } else if (paymentMethod.Type ==='CreditCard') {
            return 'Sie zahlen derzeit mit der Kreditkarte ' + paymentMethod.CreditCardType + ' ' + paymentMethod.CreditCardMaskNumber + '.';
          } else if (paymentMethod.Type ==='PayPal') {
            return 'Sie zahlen derzeit mit Paypal über den Account ' + paymentMethod.PaypalEmail + '.';
          }
        }
      };

      var setPaymentMethod = function(paymentMethod) {
        vm.paymentMethod = paymentMethod;
        vm.paymentPageLoading = true;
        PaymentMethod.initPaymentMethod(paymentMethod).finally(function() {
          vm.paymentPageLoading = false;
        });
      };

      vm.usesCreditCard = function() {
        return vm.paymentMethod == 'creditcard';
      };

      vm.usesSepa = function() {
        return vm.paymentMethod == 'sepa';
      };

      vm.usesPaypal = function() {
        return vm.paymentMethod == 'paypal';
      };

      vm.setCreditcard = function() {
        if (!vm.usesCreditCard()) setPaymentMethod('creditcard');
      };

      vm.setSepa = function() {
        if (!vm.usesSepa()) setPaymentMethod('sepa');
      };

      vm.setPaypal = function() {
        if (!vm.usesPaypal()) setPaymentMethod('paypal');
      };

      vm.getBankAccountName = function() {
        var bankAccountName = '';
        if (vm.sepa === undefined) return bankAccountName;
        if (vm.sepa.firstName !== undefined) {
          bankAccountName = vm.sepa.firstName + " ";
        }
        if (vm.sepa.lastName !== undefined) bankAccountName = bankAccountName + vm.sepa.lastName;
        return bankAccountName;
      };

      vm.getFullName = function() {
        if (!Account.getAccount().firstName) return "";
        return Account.getAccount().firstName + " " + Account.getAccount().lastName;
      };

      vm.editProfile = function() {
        $scope.$emit('account:openProfile');
      };

      vm.hasAgreed = function() {
        return Account.hasAgreed() || !Subscription.isBusiness();
      };

      vm.saveAccount = function() {
        if (idle.account) return;
        error.account = null;
        idle.account = true;
        vm.isSavingAccount = true;
        status.account = "Ihr Benutzerkonto wird aktualisiert...";
        Validator.validate().then(function() {
          Account.saveAccount().then(function() {
            vm.isSavingAccount = false;
            status.account = null;
            idle.account = false;

            if (Account.getAccount().cdpConsent !== true && Account.getAccount().agreedToTerms) {
              Account.getAccount().cdpConsent = true;
              Account.getAccount().cdpConsentDate = new Date().toISOString();
              Account.getAccount().isBusiness = Subscription.isBusiness();
            }
            $scope.$emit('account:update', {account: Account.getAccount()});
          }).catch(function() {
            status.account = null;
            idle.account = false;
            error.account = "Ihre Rechnungsdaten konnten nicht gespeichert werden. Bitte versuchen Sie es erneut oder kontaktieren Sie unseren Kundenservice. Sie erreichen uns Montag bis Freitag jeweils von 8 - 18 Uhr unter 0800 2684268 oder service@smartlaw.de. Vielen Dank für Ihr Verständnis."
          });
        }).catch(function(response) {
          status.account = null;
          idle.account = false;
          if (response.data !== undefined || response.errorMessage !== undefined) {
            var errorMessage = null;

            if (response.data.errorMessage !== undefined) {
              errorMessage = response.data.errorMessage;
            }
            if (response.errorMessage !== undefined) {
              errorMessage = response.errorMessage;
            }
            error.account = errorMessage
          } else {
            error.account = response;
          }
        });
      };

      var createTimeout = function() {
        return $timeout(function() {
          return $q.reject("Die Zahlungsmethode konnte nicht aktualsiert werden. Bitte versuchen Sie es erneut.");
        }, 15000);
      };

      vm.savePayment = function() {
        if (idle.payment) return;
        error.payment = null;
        idle.payment = true;
        vm.isSavingPayment = true;
        status.payment = "Ihre Zahlungsart wird überprüft...";

        (function() {
          if (PaymentMethod.getMethod() !== 'paypal') {
            return $q.race([PaymentMethod.submitPaymentMethod(), createTimeout()]);
          }
          return $q.reject('Paypal wird hier noch nicht unterstützt.');
        })().then(function(refId) {
          if (PaymentMethod.getMethod() !== 'sepa') {
            return refId;
          }
          sepaConfirmationDeferred = $q.defer();
          sepaConfirmationDeferred.refId = refId;
          bNeedsSEPAConfirmation = true;
          return sepaConfirmationDeferred.promise;
        }).then(function(refId) {
          var paymentData = {
            paymentMethodId: refId,
            paymentMethod: PaymentMethod.getMethod()
          };
          status.payment = "Ihre Zahlungsart wird gespeichert...";
          return $http.put(slConstant.host +'/accounts/' + Account.getAccount().Id + '/payments', paymentData);
        }).then(function(response) {
          Account.getAccount().paymentMethod = response.data;
          vm.createNewPaymentMethod = false;
          vm.isSavingPayment = false;
          status.payment = null;
          idle.payment = false;
          $scope.$emit('payment:update', {account: Account.getAccount()});
        }).catch(function(response) {
          status.payment = null;
          idle.payment = false;
          if (response.data !== undefined || response.errorMessage !== undefined) {
            var errorMessage = null;
            if (response.data.errorMessage !== undefined) {
              errorMessage = response.data.errorMessage;
            }
            if (response.errorMessage !== undefined) {
              errorMessage = response.errorMessage;
            }
            error.payment = errorMessage
          } else {
            error.payment = response;
          }
        });
      };
      vm.confirmCancelSubscription = function() {
            console.log(vm.getSubscriptions().name);
            if(vm.typeOfCancellation == 'außerordentlich' && (!vm.cancellationReasonText || vm.cancellationReasonText == '')){
                vm.requiredField = 'required';
            }else if(vm.getSubscriptions().name == undefined || vm.getSubscriptions().name == '') {
                vm.requiredDropdown = 'required';
            }else {
                $http.post(slConstant.host +'/createPDF/', {
                    data:  {
                        typeOfCancellation : vm.typeOfCancellation,
                        cancellationReasonText : vm.cancellationReasonText,
                        name : vm.getFullName(),
                        accountData : vm.getAccount(),
                        smartlawId: Account.getSmartlawId(),
                        subscriptionType : vm.getSubscriptions().name
                    }
                }).then(res=>{
                    $http.post(slConstant.host +'/emailSend/', {
                        to: vm.getAccount().email,
                        subject: 'Ihre Abo-Kündigung bei Smartlaw',
                        text: 'Ihre Abo-Kündigung bei Smartlaw',
                        htmlCustomer: '<table cellpadding="14" cellspacing="0" width="100%"> <tr> <td> <h3>Hallo ' + vm.getFullName() + ',</h3><p>Ihr Kündigungswunsch des Smartlaw-Abos ist bei uns eingegangen. Im Anhang finden Sie Ihre Angaben zur Kündigung. Wir werden Ihr Anliegen zeitnah bearbeiten und senden Ihnen dann eine Mail mit den genauen Kündigungs-Informationen. Mit der Kündigung Ihres Abos werden alle Daten bei uns gelöscht.</p> </td> </tr><tr><td><p>Mit freundlichen Grüßen<br>Ihr Smartlaw-Team</p></td></tr></table> ',
                        htmlSupport: '<table cellpadding="14" cellspacing="0" width="100%"> <tr> <td> <h3>Hallo ' + vm.getFullName() + ',</h3><p>Ihr Kündigungswunsch des Smartlaw-Abos (SmartlawAccountId : '+ Account.getAccount().Id +') ist bei uns eingegangen. Im Anhang finden Sie Ihre Angaben zur Kündigung. Wir werden Ihr Anliegen zeitnah bearbeiten und senden Ihnen dann eine Mail mit den genauen Kündigungs-Informationen. Mit der Kündigung Ihres Abos werden alle Daten bei uns gelöscht.</p> </td> </tr><tr><td><p>Mit freundlichen Grüßen<br>Ihr Smartlaw-Team</p></td></tr></table> ',
                        attachments: [{
                            filename: 'Ihre_Kündigung.pdf',
                            path: 'public/' + res.data.path,
                            contentType: 'application/pdf'
                        }],
                        url: slConstant.host +'/'+ res.data.path
                    }).then(res=>{
                        vm.showErrorMessage = 'Die Email wurde erfolgreich gesendet.';
                        vm.showCancellationSucessMessage = true;
                        vm.requiredField = '';
                        vm.requiredDropdown = '';
                        vm.getSubscriptions().name = '';
                    });
                    vm.pdfPath = res.data.path;
                    vm.pdfURL = slConstant.host +'/downloadPDF/?url='+ res.data.path;
                });
            }
      };
      vm.closeCancelSubscriptionPopUp = function() {
          $http.get(slConstant.host +'/downloadPDF/', {
              params: {url: vm.pdfPath, flag: true}
          }).then(res=>{
              vm.showErrorMessage = 'Ihr PDF heruntergeladen.';
          }, err=>{
              vm.showErrorMessage = 'Die Datei nicht gefunden.';
          });
          vm.isDisabled = false;
          vm.showKundigungPopUp = false;
          vm.typeOfCancellation = 'ordentlich';
          vm.cancellationReasonText = '';
          vm.getSubscriptions().name = '';
      };

      vm.confirmMandate = function() {
        if (sepaConfirmationDeferred !== undefined) {
          sepaConfirmationDeferred.resolve(sepaConfirmationDeferred.refId);
        }
        bNeedsSEPAConfirmation = false;
      };

      vm.declineMandate = function() {
        if (sepaConfirmationDeferred !== undefined) {
          sepaConfirmationDeferred.reject("Sie haben das SEPA-Lastschriftmandat nicht angenommen.");
        }
        bNeedsSEPAConfirmation = false;
      };

      vm.needsSEPAConfirmation = function() {
        return bNeedsSEPAConfirmation;
      };

      vm.showKundigungPopUpOpen = function() {
        $scope.$emit('account:aboCancellation');
      };

      vm.cancel = function() {
        $scope.$emit('account:helpdesk');
      };

      vm.hasInvoices = function() {
        return Invoice.getInvoices() && Invoice.getInvoices().length > 0;
      };

      vm.hasSubscriptions = function() {
        return Subscription.getSubscriptions() && Subscription.getSubscriptions().length > 0;
      };

      vm.hasActiveSubscriptions = function() {
        return Subscription.hasActiveSubscriptions();
      };

      vm.getStatus = function(section) {
        return status[section];
      };

      vm.hasFormError = function(field) {
        return Validator.hasError(field);
      };

      vm.getError = function(section) {
        return error[section];
      };

      vm.toggleCDP = function() {
        if (Account.hasAgreed()) {
          Account.getAccount().agreedToTerms = true;
        }
      }

    }]);
})();
