(function() {
  'use strict';

  angular.module('app.checkout').controller('CheckoutCtrl', [
    'slConstant',
    'Account',
    'Order',
    'PaymentMethod',
    'Validator',
    '$scope',
    '$q',
    '$location',
    function(slConstant, Account, Order, PaymentMethod, Validator, $scope, $q, $location) {
      var vm = this;
      var registrationDeferred;
      var idle = false;

      var rpid = $scope.rpid;
      var aid = $scope.aid;
      var state = $scope.state;

      if (state !== undefined && state !== 'undefined') {
        Order.setState(state);
      }

      vm.getAccount = Account.getAccount;
      vm.getOrder = Order.getOrder;
      vm.hasRateplan = Order.hasRateplan;
      vm.getPaymentPageParams = PaymentMethod.getPaymentPageParams;
      vm.paymentPageLoading = false;
      vm.hasCDPAgreed = false;

      vm.imageCreditcardUrl = slConstant.host + "/images/logo_mastercard+visa.svg";
      vm.imageSepaUrl = slConstant.host + "/images/logo_lastschrift.svg";
      vm.imagePaypalUrl = slConstant.host + "/images/logo_paypal.svg";

      var checkPaypal = function() {
        return $q(function(resolve, reject) {
          if ($location.search().paypalsuccess === 'true')  {
            resolve();
          } else if ($location.search().paypalsuccess === 'false') {
            reject('Ihr Paypal Account konnte nicht bestätigt werden.');
          } else {
            reject();
          }
        });
      };

      if (rpid !== undefined && rpid !== 'undefined') {
        Account.initAccount(aid).then(function() {
          if (Account.getAccount().paymentMethod === undefined) {
            setPaymentMethod('sepa');
          }
          if (Account.hasAgreed()) {
            vm.hasCDPAgreed = true;
          }

          return Order.initOrder(rpid);
        }).then(function() {
          var isBusiness = ((Order.getOrder().product.is_business_product__c || '').toUpperCase() === 'TRUE') || (Account.hasAgreed());
          vm.editBillingAddress = isBusiness;
          vm.isBusiness = vm.editBillingAddress;
          vm.isCheckoutReady = true;
          if (vm.isBusiness) {
            Account.setBusiness();
          }
        }).catch(function(response) {
          vm.isCheckoutReady = true;
          vm.errorMessage = response.errorMessage;
          $scope.$emit('checkout:error', { errorMessage: vm.errorMessage, state: Order.getSavedState() });
        });
      } else {
        checkPaypal().then(Order.continueCheckout).then(function(data) {
          if (data.account.cdpConsent !== true) {
            // true means that the user had agreed before
            data.account.cdpConsentDate = new Date().toISOString();
          }

          data.account.isBusiness = (data.order.product.is_business_product__c || '').toUpperCase() === 'TRUE';
          $scope.$emit('checkout:success', data);
        }).catch(function(errorMessage) {
          vm.isCheckoutReady = true;
          if (errorMessage) {
            vm.errorMessage = errorMessage;
          } else {
            vm.errorMessage = 'Es wurde kein Produkt ausgewählt. Bitte versuchen Sie es erneut oder kontaktieren Sie unseren Kundenservice. Sie erreichen uns Montag bis Freitag jeweils von 8 - 18 Uhr unter 0800 2684268 oder service@smartlaw.de. Vielen Dank für Ihr Verständnis.';
          }
          $scope.$emit('checkout:error', { errorMessage: vm.errorMessage, state: Order.getSavedState() });
        });
      }

      vm.getPaymentMethodText = function() {
        if (Account.getAccount().paymentMethod !== undefined) {
          var paymentMethod = Account.getAccount().paymentMethod;
          if (paymentMethod.Type ==='BankTransfer') {
            return 'Sie zahlen mit SEPA Lastschrift über das Konto ' + paymentMethod.BankTransferAccountNumberMask + '.';
          } else if (paymentMethod.Type ==='CreditCard') {
            return 'Sie zahlen mit der Kreditkarte ' + paymentMethod.CreditCardType + ' ' + paymentMethod.CreditCardMaskNumber + '.';
          } else if (paymentMethod.Type ==='PayPal') {
            return 'Sie zahlen mit Paypal über den Account ' + paymentMethod.PaypalEmail + '.';
          }
        }
      };

      var setPaymentMethod = function(paymentMethod) {
        vm.paymentMethod = paymentMethod;
        Order.clearError();
        vm.paymentPageLoading = true;
        Order.clearError();
        PaymentMethod.initPaymentMethod(paymentMethod).finally(function() {
          vm.paymentPageLoading = false;
        });
      };

      vm.usesCreditCard = function() {
        return vm.paymentMethod === 'creditcard';
      };

      vm.usesSepa = function() {
        return vm.paymentMethod === 'sepa';
      };

      vm.usesPaypal = function() {
        return vm.paymentMethod === 'paypal';
      };

      vm.setCreditcard = function() {
        if (!vm.usesCreditCard()) {
          setPaymentMethod('creditcard');
        }
      };

      vm.setSepa = function() {
        if (!vm.usesSepa()) {
          setPaymentMethod('sepa');
        }
      };

      vm.setPaypal = function() {
        if (!vm.usesPaypal()) {
          setPaymentMethod('paypal');
        }
      };

      var isIdle = function() {
        return idle;
      };

      vm.isIdle = isIdle;
      vm.closeOneTimePurchasePopUp = function () {
        vm.showOneTimePurchasePopUp = false;
        $scope.$emit('checkout:success', vm.checkoutData);
      }
      vm.closeSubscriptionPurchasePopUp = function () {
        vm.showSubscriptionPurchasePopUp = false;
        $scope.$emit('checkout:success', vm.checkoutData);
      }

      vm.submitOrder = function () {
        vm.showOneTimePurchasePopUp = false;
        vm.showSubscriptionPurchasePopUp = false;
        if (isIdle()) {
          return;
        }
        Order.setStatus(null);
        Order.setError(null);
        Account.setOrderSubmittedState(true);

        if (vm.paymentPageLoading) {
          vm.errorMessage = 'Die Zahlungsmethode ist noch nicht bereit.';
          return;
        }
        idle = true;
        var useExistingPaymentmethod = Account.getAccount().paymentMethod !== undefined && !vm.createNewPaymentMethod;

        Validator.validate().then(function() {
          if (Account.isDefined()) {
            return $q.when();
          }
          registrationDeferred = $q.defer();
          Order.setStatus('Ihr Benutzerkonto wird erstellt...');
          $scope.$emit('checkout:register', { account: Account.getAccount() });

          return registrationDeferred.promise;
        }).then(function() {
          return Order.checkout(useExistingPaymentmethod).then(function(data) {
            if (data.account.cdpConsent !== true) {
              // true means that the user had agreed before
              data.account.cdpConsentDate = new Date().toISOString();
            }

            data.account.isBusiness = (data.order.product.is_business_product__c || '').toUpperCase() === 'TRUE';
            vm.checkoutData = data;
            if (Order.getOrder().rateplan.SubscriptionType__c === "OneTime") {
              vm.showOneTimePurchasePopUp = true;
            } else if (Order.getOrder().rateplan.SubscriptionType__c === "Subscription" && vm.paymentMethod === "creditcard") {
              vm.showSubscriptionPurchasePopUp = true;
            } else {
              $scope.$emit('checkout:success', vm.checkoutData);
            }
          }).catch(function(errorMessage) {
            if (errorMessage) {
              $scope.$emit('checkout:error', { errorMessage: errorMessage, state: Order.getState() });
            }
            idle = false;
          });
        }).catch(function(error) {
          Order.setStatus(null);
          Order.setError(error);
          idle = false;
        });
      }

      $scope.$on('registration:error', function() {
        if (registrationDeferred) {
          registrationDeferred.reject('Die Registrierung ist fehlgeschlagen.');
        }
        Order.setStatus(null);
        idle = false;
      });

      $scope.$on('registration:success', function(event, accountId) {
        if (registrationDeferred) {
          Account.updateAccount(accountId).then(function() {
            registrationDeferred.resolve();
          }).catch(function(err) {
          });
        }
      });

      vm.applyVoucherCode = function() {
        if (!!vm.voucherCode && !vm.isVoucherLoading) {
          vm.isVoucherLoading = true;
          Order.initOrder(rpid, vm.voucherCode).then(function(order) {
            vm.isVoucherCodeInvalid = (order.voucher.error !== undefined);
            vm.isVoucherLoading = false;
          }).catch(function() {
            vm.isVoucherCodeInvalid = true;
            vm.isVoucherLoading = false;
          });
        }
      };

      vm.keyDownVoucherCode = function(event) {
        var k = event.which || event.key;
        if (!vm.isVoucherLoading) {
          if (k === 13) {
            vm.applyVoucherCode();
          }
        }
      };

      vm.changeVoucherCode = function() {
        if (!vm.isVoucherLoading) {
          vm.isVoucherCodeInvalid = false;
        }
      };

      vm.removeVoucherCode = function() {
        vm.voucherCode = '';
        Order.removeVoucherCode();
      };

      vm.showVoucherBox = function() {
      };

      var hasDiscount = function() {
        return Order.getOrder().voucher !== undefined && Order.getOrder().voucher.error === undefined;
      };

      vm.hasDiscount = hasDiscount;

      vm.isRecurring = function() {
        return (Order.getOrder().rateplan === undefined || Order.getOrder().rateplan.SubscriptionType__c ===
          'Subscription');
      };

      vm.isBillingPeriodExists = function () {
        return Order.getOrder().subscription.billingPeriod !== '';
      }

      vm.needsMandateConfirmation = function() {
        return Order.needsSEPAConfirmation();
      };

      vm.confirmMandate = function() {
        return Order.confirmMandate();
      };

      vm.declineMandate = function() {
        return Order.declineMandate();
      };

      vm.getAmount = function() {
        if (hasDiscount()) {
          return Order.getOrder().voucher.amount;
        }
        return Order.getOrder().subscription.amount;
      };

      vm.getTaxAmount = function() {
        if (hasDiscount()) {
          return Order.getOrder().voucher.taxAmount;
        }
        return Order.getOrder().subscription.taxAmount;
      };

      vm.getNettoAmount = function() {
        if (hasDiscount()) {
          return Order.getOrder().voucher.amountWithoutTax;
        }
        return Order.getOrder().subscription.amountWithoutTax;
      };

      vm.getVoucherFootnote = function() {
        if (!hasDiscount()) {
          return;
        }
        if (Order.getOrder().voucher.footnote === undefined || Order.getOrder().voucher.footnote === '') {
          return;
        }
        return ': ' + Order.getOrder().voucher.footnote;
      };

      vm.getVoucherFootnote2 = function() {
        if (!hasDiscount()) {
          return;
        }
        return Order.getOrder().voucher.footnote2;
      };

      vm.getBankAccountName = function() {
        var bankAccountName = '';
        if (vm.sepa === undefined) {
          return bankAccountName;
        }
        if (vm.sepa.firstName !== undefined) {
          bankAccountName = vm.sepa.firstName + ' ';
        }
        if (vm.sepa.lastName !== undefined) {
          bankAccountName = bankAccountName + vm.sepa.lastName;
        }
        return bankAccountName;
      };

      vm.getStatus = function() {
        return Order.getStatus();
      };

      vm.hasFormError = function(field) {
        return Validator.hasError(field);
      };

      vm.getError = function() {
        return Order.getError();
      };

      vm.getSubmitButton = function() {
        if (Order.getOrder() !== undefined && Order.getOrder().subscription.trial) {
          return 'JETZT BESTELLEN – KOSTENPFLICHTIG NACH DER TESTPHASE';
        }
        return 'JETZT KOSTENPFLICHTIG BESTELLEN';
      };

      vm.hasAccount = function() {
        return Account.isDefined();
      };

      vm.login = function() {
        $scope.$emit('checkout:login');
      };

      vm.toggleEditBillingAddress = function() {
        if (vm.isBusiness) {
          vm.editBillingAddress = true;
        }
      }
    }]);
})();
