(function() {
  angular.module('app.customer')
    .factory('Invoice', ['Account', '$http', '$q', 'slConstant', function(Account, $http, $q, slConstant) {
      var invoices = [];

      var initInvoices = function() {
        if (Account.getAccount().Id === undefined) return $q.reject();

        return $http.get(slConstant.host + '/accounts/' + Account.getAccount().Id + '/invoices').then(function(result) {
          invoices = result.data;
          return invoices;
        });
      };

      var getInvoices = function() {
        return invoices;
      };

      return {
        initInvoices: initInvoices,
        getInvoices: getInvoices
      };
    }])
    .factory('Subscription', ['Account', '$http', '$q', 'slConstant', function(Account, $http, $q, slConstant) {
      var subscriptions = [];
      var hasActive = false;
      var hasBusiness = false;

      var initSubscriptions = function() {
        if (Account.getAccount().Id === undefined) return $q.reject();

        return $http.get(slConstant.host + '/accounts/' + Account.getAccount().Id + '/subscriptions').then(function(result) {
          allSubscriptions = result.data;

          if (allSubscriptions) {
            for (var i = 0; i < allSubscriptions.length; i++) {
              console.log(allSubscriptions[i]);
              if (allSubscriptions[i].type === 'Subscription') {
                subscriptions.push(allSubscriptions[i]);
                if (!allSubscriptions[i].canceledDate) {
                  hasActive = true;
                }
              }
              if ((allSubscriptions[i].product.is_business_product__c || '').toUpperCase() === 'TRUE') {
                hasBusiness = true;
              }
            }
          }
          return allSubscriptions;
        });
      };

      var getSubscriptions = function() {
        return subscriptions;
      };

      var hasActiveSubscriptions = function() {
        return hasActive;
      };

      var isBusiness = function() {
        return hasBusiness;
      };

      return {
        initSubscriptions: initSubscriptions,
        getSubscriptions: getSubscriptions,
        hasActiveSubscriptions: hasActiveSubscriptions,
        isBusiness: isBusiness
      };
    }]);
})();
